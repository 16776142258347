<template>
  <div class="media-app">
    <header>
      <ul class="tabs">
        <li v-for="(item,index) in tableList" style="margin: 0 68px" :key="index" @click="changeTab(index)" :class="{active:index==tableLists}">{{item}}</li>
      </ul>
      <!-- <Upload :style="{ visibility: tab === 0 ? 'visible' : 'hidden' }"
              action="图片地址"
              multiple>
        <div class="tab-btn">上传</div>
      </Upload> -->
    </header>

    <!-- 照片 -->
    <div v-if="tableLists === 0">
      <div class="img-list" v-if="imgdata.length>0">
        <ul>
          <viewer :images="imgdata" @hidden="imgdataHideBut" >
            <li v-for="(data,index) in imgdata"
                :key="index" @click="imgdataBut(date)">
            <!--  <img :src="data.file_path" />-->
              <img :src="imgbox?data.file_path:data.thumbnail" />
              <p class="media-date">{{ data.file_name }}</p>
            </li>
          </viewer>
        </ul>
      </div>
      <div v-if="imgdata.length === 0" class="noMedia">
        <img src="@/assets/img/noData.png" alt=""/>
        暂无图片文件
      </div>
    </div>
    <!-- 视频 -->
    <div v-if="tableLists === 1">
      <div class="img-list"
           v-if="videodata.length>0">
        <ul>
          <li v-for="(data) in videodata" :key="data.id" @click="playVideo(data.file_path)">
            <video :src="data.file_path"></video>
            <p class="media-date">{{ data.file_name }}</p>
            <!-- <Button type="error"
                    size="small"
                    shape="circle"
                    class="delPicBtn"
                    @click.stop="delMedia(data.id)"
                    icon="md-close">
            </Button> -->
          </li>
        </ul>
      </div>
      <div v-if="videodata.length === 0"
           class="noMedia">
        <img src="@/assets/img/noData.png" alt=""/>
        暂无视频文件
      </div>
    </div>
    <!-- 直播录制 -->
    <div v-if="tableLists === 2">
      <div class="img-list"
           v-if="LiveTranscribeList.length>0">
        <ul>
          <li v-for="(data) in LiveTranscribeList" :key="data.stream_id" @click="playVideo(data.video_url)">
            <video :src="data.video_url"></video>
            <p class="media-date">{{ data.stream_name }}</p>
          </li>
        </ul>
      </div>
      <div v-if="LiveTranscribeList.length === 0" class="noMedia">
        <img src="@/assets/img/noData.png" alt=""/>
        暂无视频文件
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- <Modal
      v-model="imgModal"
      footer-hide
      :title="imgModalData.time"
      width="832"
      @on-cancel="
        imgModalData.lists = [];
        imgModalData.time = '';
      "
    >
      <Carousel v-if="imgModalData.lists.length > 0" dots="outside">
        <CarouselItem v-for="u in imgModalData.lists" :key="u.index">
          <div
            class="demo-carousel"
            :style="{ backgroundImage: 'url(' + u + ')' }"
          ></div>
        </CarouselItem>
      </Carousel>
    </Modal> -->
    <!-- 视频窗口 -->
    <article v-show="videoModal">
      <Icon type="md-close"
            color="white"
            class="close"
            size="20"
            @click="closeVideoModal()" />
      <video controls
             ref="video"
             :src="bigVideo"
             autoplay>
        <!-- <source :src="bigVideo" type="video/mp4" />
        您的浏览器不支持Video标签。 -->
      </video>
    </article>
    <aside v-show="imgModal">
      <p class="img-modal-time">{{ imgModalData.time }}</p>
      <Icon type="md-close"
            color="white"
            class="close"
            size="30"
            @click="closeImgModal" />
      <div class="big-img-box"
           :style="{ backgroundImage: 'url(' + bigImgUrl + ')' }"></div>
      <div class="arrow"
           @click="backImg"
           style="left: 10px">
        <Icon type="ios-arrow-back"
              color="white"
              size="24" />
      </div>
      <div class="arrow"
           @click="forwardImg"
           style="right: 10px">
        <Icon type="ios-arrow-forward"
              color="white"
              size="24" />
      </div>
      <div class="big-list-box">
        <ul>
          <li v-for="(l, index) in imgModalData.lists"
              :key="index">
            <img :src="l"
                 @click="chooseImg(index)" />
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
import Api from '@/utils/api.js';
export default {
  created() {},
  data() {
    return {
      tab: 0,
      imgdata: [],
      videodata: [],
      imgModal: false,
      imgModalData: {
        time: '',
        lists: [],
      },
      bigImgUrl: '',
      bigIndex: 0,
      workspaceID: '',
      videoModal: false,
      bigVideo: '',
      tableList:['照片','视频','直播录制'],
      LiveTranscribeList:'',//直播录制
      tableLists:0,
      closeWebsocket: false, //是否关闭Websocket
      websock: null,
      imgbox:false,
    };
  },
  mounted() {
    this.workspaceID = sessionStorage.getItem('sy-workspace_id');
    this.getMedia();
    this.getLiveTranscribe()

    //连接Websocket
   // this.InitWebsocket(); //临时屏蔽Socket连接
  },
  computed: {

  },
  beforeDestroy() {
    // 关闭页面
    this.websocketclose()
  },
  methods: {
    imgdataBut(e){
      this.imgbox=true
    },
    imgdataHideBut(){
      this.imgbox=false
    },
    extname(filename) {
      if (!filename || typeof filename != 'string') {
        return false;
      }
      let a = filename.split('').reverse().join('');
      let b = a.substring(0, a.search(/\./)).split('').reverse().join('');
      return b;
    },
    // 获取媒体资源
    getMedia() {
      this.imgdata = [];
      this.videodata = [];
      this.$sy_get('/media/api/v1/files/' + this.workspaceID + '/files')
        .then((res) => {
          if (res.code == 0) {
            console.log('res',res)
            res.data.forEach((element) => {
              if (this.extname(element.object_key) === 'mp4') {
                this.videodata.push(element);
              } else {
                this.imgdata.push(element);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '获取媒体列表接口异常,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },
    getLiveTranscribe(){
      this.$post('/pilot/getLiveTranscribe').then(res=>{
        this.LiveTranscribeList =res.data
        console.log('1111111111111111111111111111111',res.data)
      })
    },
    // 切换tab栏
    changeTab(e) {
      this.tableLists=e

    },
    // 连接Websocket
    InitWebsocket() {
      const wsuri = Api.getSY_WebSocketUrl() + '?x-auth-token=' + sessionStorage.getItem('sy_token');
      this.websock = new WebSocket(wsuri);
      console.log('连接成功websock666666666666',this.websock);

      // console.log("webcock 实例对象", this.websock);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      // 建立连接
      console.log('开启websock成功');
      let actions = { test: '12345' };
      this.websocksend(JSON.stringify(actions));
    },
    websocketonmessage(webevent) { //获取值
      console.log('sbsbbsbsbsbsb',JSON.parse(webevent.data))
      let res=  JSON.parse(webevent.data)
      if(res.data.content==='refresh') {
        this.getMedia()
      }


    },
    websocketonerror() {
      // 错误信息
      console.log('websock 重连1111111');
      this.InitWebsocket();
    },
    websocketclose() {
      this.websock.close()
      this.websock=null
      // websocket被关闭
      console.log(' 关闭 websock');
    },
    websocksend(data) {
      console.log('websockslllllllllllllllll',data);
      this.websock.send(data);
    },
    /**
     * 照片
     */
    // 查看大图
    bigImg(m, idx) {
      this.imgModal = true;
      this.imgModalData.time = m.time;
      this.imgModalData.lists = m.lists;
      this.bigImgUrl = this.imgModalData.lists[idx];
      this.bigIndex = idx;
      setTimeout(() => {
        this.changeBigListBoxChoose(idx);
      }, 10);
    },
    chooseImg(idx) {
      this.bigIndex = idx;
      this.bigImgUrl = this.imgModalData.lists[idx];
      this.changeBigListBoxChoose(idx);
    },
    forwardImg() {
      if (this.bigIndex < this.imgModalData.lists.length - 1) {
        this.bigIndex++;
        this.bigImgUrl = this.imgModalData.lists[this.bigIndex];
        this.changeBigListBoxChoose(this.bigIndex);
      }
    },
    backImg() {
      if (this.bigIndex > 0) {
        this.bigIndex--;
        this.bigImgUrl = this.imgModalData.lists[this.bigIndex];
        this.changeBigListBoxChoose(this.bigIndex);
      }
    },
    // big-list-box的选中样式
    changeBigListBoxChoose(n) {
      let lis = document.querySelectorAll('.big-list-box>ul>li');
      console.log(lis);
      lis.forEach((e) => {
        e.setAttribute('class', '');
      });
      lis[n].setAttribute('class', 'active');
    },
    // 关闭弹窗
    closeImgModal() {
      this.imgModal = false;
      this.imgModalData.time = '';
      this.imgModalData.lists = [];
    },
    /**
     * 视频
     */
    playVideo(path) {
      document.documentElement.style.overflowY = 'hidden';
      this.bigVideo = path;
      this.videoModal = true;
    },
    closeVideoModal() {
      document.documentElement.style.overflowY = 'auto';
      this.$refs.video.pause();
      this.bigVideo = '';
      this.videoModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.media-app {
  width: 100%;
  min-width: 1000px;
  height: 100%;
  // position: relative;
  header {
    background-color: #464d54;
    color: #fff;
    // position: absolute;
    width: 100%;
    // z-index: 2;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tabs {
      display: inline-flex;
      li {
        padding: 0 16px;
        border-radius: 18px;
        cursor: pointer;
        height: 29px;
        line-height: 29px;
      }
      .active {
        background-color: #fff;
        color: #464d54;
      }
    }
    .tab-btn {
      width: 60px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      background-color: #2196f3;
      text-align: center;
      font-size: 14px;
    }
  }
  .img-list {
    margin: 20px 0 15px 28px;
    p {
      font-size: 12px;
      margin-bottom: 10px;
    }
    ul {
      li {
        display: inline-block;
        width: 234px;
        height: 234px;
        overflow: hidden;
        text-align: center;
        margin-right: 25px;
        cursor: pointer;
        overflow: hidden;
        img {
          height: 234px;
        }
        p{
          text-align:center;
          color: white;
          font-size: 14px;
        }
        video {
          height: 200px;
        }
      }
    }
  }
}
.demo-carousel {
  width: 800px;
  height: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
aside {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  .img-modal-time {
    color: #fff;
    font-size: 16px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .big-img-box {
    width: 90%;
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  .arrow {
    position: absolute;
    top: 51%;
    width: 40px;
    height: 40px;
    line-height: 45px;
    text-align: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
  }
  .big-list-box {
    width: 100%;
    height: 100px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        width: 62px;
        height: 62px;
        overflow: hidden;
        margin: 15px 5px 0;
        border: 4px solid #000;
        box-sizing: content-box;
        img {
          height: 62px;
        }
      }
      .active {
        border: 4px solid #4c98ff;
      }
    }
  }
}
article {
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 5px;
    cursor: pointer;
    z-index: 99;
    background-color: #000;
    border-radius: 50%;
  }
  video {
    max-height: 100%;
    max-width: 100%;
  }
}
  .noMedia{
    text-align: center;
    margin: 0 auto;
    margin-top:5%;
    font-size: 20px;
    img{
      margin:20px auto;
      text-align: center;
    }
  }
</style>

